const advent_days_SI = [
    {
        id: 1,
        prize_image: 'HR-11.png',
        grid_image: 'KALENDAR-adventski_1-24.jpeg',
        tail_image: 'rep-zvijezde-1-dan-min.png',
        prizes: [
            {
                title: 'Premium pekač za klasično potico/šarkelj, Twist 22 cm',
                url: 'https://oetker-shop.si/izdelek/premium-pekac-za-klasicno-potico-sarkelj-klasicni-22-cm/'
            }
        ]
    },
    {
        id: 2,
        prize_image: 'HR-03.png',
        grid_image: 'KALENDAR-adventski_2-24.jpg',
        tail_image: 'rep-zvijezde-2-dan-min.png',
        prizes: [
            {
                title: 'Božični bundle',
                url: 'https://oetker-shop.si/okusi-bozica/'
            },
            {
                title: 'Pekač v obliki božičnega drevesa, Preliv za kolače – temna kakavova glazura, Modelčki za piškote v obliki možička, 3 velikosti i ožični glamurozni mix 115g',
                url: ''
            }
        ]
    },
    {
        id: 3,
        prize_image: 'SLO-05.png',
        grid_image: 'KALENDAR-adventski_3-24.jpg',
        tail_image: 'rep-zvijezde-3-dan-min.png',
        prizes: [
            {
                title: 'Darilni bon 50 evrov',
                url: ''
            }
        ]
    },
    {
        id: 4,
        prize_image: 'HR-06.png',
        grid_image: 'KALENDAR-adventski_4-24.jpg',
        tail_image: 'rep-zvijezde-4-dan-min.png',
        prizes: [
            {
                title: 'Set za tortu',
                url: 'https://oetker-shop.si/okusi-bozica/'
            },
            {
                title: 'Okrogel pekač z visokim robom, Lopatica za glajenje z visokim robom, 24 cm, 24 cm i Vrtljiv podstavek za torte in ostale slaščice',
                url: ''
            }
        ]
    },
    {
        id: 5,
        prize_image: 'HR-09.png',
        grid_image: 'KALENDAR-adventski_5-24.jpg',
        tail_image: 'rep-zvijezde-5-dan-min.png',
        prizes: [
            {
                title: 'Taste of Christmas set',
                url: 'https://oetker-shop.si/okusi-bozica/'
            },
            {
                title: 'Set za izdelavo in okrasitev rjavčkov (brownijev, Set za izdelavo in okrasitev božičnih piškotov, Božična sladka hiška z okraski, + Dekor za božično zabavo + Lepilo za peko',
                url: ''
            }
        ]
    },
    {
        id: 6,
        prize_image: 'HR-08.png',
        grid_image: 'KALENDAR-adventski_6-24.jpg',
        tail_image: 'rep-zvijezde-6-dan-min.png',
        prizes: [
            {
                title: 'Set za Božični kruh',
                url: 'https://oetker-shop.si/okusi-bozica/'
            },
            {
                title: 'Pekač za peko božičnega kruha + Vanilin sladkor 6x8g+Kvas instant 4/1 + Finesse pomarančna lupinica 3x6g +Kakao za kolače + Posoda za mešanje 3,2 l',
                url: ''
            }
        ]
    },
    {
        id: 7,
        prize_image: 'SLO-10.png',
        grid_image: 'KALENDAR-adventski_7-24.jpg',
        tail_image: 'rep-zvijezde-7-dan-min.png',
        prizes: [
            {
                title: 'Mikser ručni MFQ4020 BOSCH',
                url: 'https://oetker-shop.si/izdelek/rocni-mesalnik-mfq4020-bosch/'
            }
        ]
    },
    {
        id: 8,
        prize_image: 'HR-09.png',
        grid_image: 'KALENDAR-adventski_8-24.jpg',
        tail_image: 'rep-zvijezde-8-dan-min.png',
        prizes: [
            {
                title: 'Taste of Christmas set',
                url: 'https://oetker-shop.si/okusi-bozica/'
            },
            {
                title: 'Set za izdelavo in okrasitev rjavčkov (brownijev, Set za izdelavo in okrasitev božičnih piškotov, Božična sladka hiška z okraski, + Dekor za božično zabavo + Lepilo za peko',
                url: ''
            }
        ]
    },
    {
        id: 9,
        prize_image: 'HR-02.png',
        grid_image: 'KALENDAR-adventski_9-24.jpg',
        tail_image: 'rep-zvijezde-9-dan-min.png',
        prizes: [
            {
                title: 'Mikser ručni MFQ49300 BOSCH',
                url: 'https://oetker-shop.si/izdelek/rocni-mesalnik-mfq49300-bosch/'
            }
        ]
    },
    {
        id: 10,
        prize_image: 'HR-06.png',
        grid_image: 'KALENDAR-adventski_10-24.jpg',
        tail_image: 'rep-zvijezde-10-dan-min.png',
        prizes: [
            {
                title: 'Set za tortu',
                url: 'https://oetker-shop.si/okusi-bozica/'
            },
            {
                title: 'Okrogel pekač z visokim robom, Lopatica za glajenje z visokim robom, 24 cm, 24 cm i Vrtljiv podstavek za torte in ostale slaščice',
                url: ''
            }
        ]
    },
    {
        id: 11,
        prize_image: 'HR-07.png',
        grid_image: 'KALENDAR-adventski_11-24.jpg',
        tail_image: 'rep-zvijezde-11-dan-min.png',
        prizes: [
            {
                title: 'Retro set',
                url: 'https://oetker-shop.si/okusi-bozica/'
            },
            {
                title: 'Pekač za torto 20 cm, retro, Roza retro pekač za pite in browniese, Pekač pravokotni, retro',
                url: ''
            }
        ]
    },
    {
        id: 12,
        prize_image: 'HR-03.png',
        grid_image: 'KALENDAR-adventski_12-24.jpg',
        tail_image: 'rep-zvijezde-12-dan-min.png',
        prizes: [
            {
                title: 'Božični bundle',
                url: 'https://oetker-shop.si/okusi-bozica/'
            },
            {
                title: 'Pekač v obliki božičnega drevesa, Preliv za kolače – temna kakavova glazura, Modelčki za piškote v obliki možička, 3 velikosti i ožični glamurozni mix 115g',
                url: ''
            }
        ]
    },
    {
        id: 13,
        prize_image: 'SLO-05.png',
        grid_image: 'KALENDAR-adventski_13-24.jpg',
        tail_image: 'rep-zvijezde-13-dan-min.png',
        prizes: [
            {
                title: 'Darilni bon 50 evrov',
                url: ''
            }
        ]
    },
    {
        id: 14,
        prize_image: 'HR-08.png',
        grid_image: 'KALENDAR-adventski_14-24.jpg',
        tail_image: 'rep-zvijezde-14-dan-min.png',
        prizes: [
            {
                title: 'Set za Božični kruh',
                url: 'https://oetker-shop.si/okusi-bozica/'
            },
            {
                title: 'Pekač za peko božičnega kruha + Vanilin sladkor 6x8g+Kvas instant 4/1 + Finesse pomarančna lupinica 3x6g +Kakao za kolače + Posoda za mešanje 3,2 l',
                url: ''
            }
        ]
    },
    {
        id: 15,
        prize_image: 'HR-07.png',
        grid_image: 'KALENDAR-adventski_15-24.jpg',
        tail_image: 'rep-zvijezde-15-dan-min.png',
        prizes: [
            {
                title: 'Retro set',
                url: 'https://oetker-shop.si/okusi-bozica/'
            },
            {
                title: 'Pekač za torto 20 cm, retro, Roza retro pekač za pite in browniese, Pekač pravokotni, retro',
                url: ''
            }
        ]
    },
    {
        id: 16,
        prize_image: 'SLO-05.png',
        grid_image: 'KALENDAR-adventski_16-24.jpg',
        tail_image: 'rep-zvijezde-16-dan-min.png',
        prizes: [
            {
                title: 'Darilni bon 50 evrov',
                url: ''
            }
        ]
    },
    {
        id: 17,
        prize_image: 'HR-09.png',
        grid_image: 'KALENDAR-adventski_17-24.jpg',
        tail_image: 'rep-zvijezde-17-dan-min.png',
        prizes: [
            {
                title: 'Taste of Christmas set',
                url: 'https://oetker-shop.si/okusi-bozica/'
            },
            {
                title: 'Set za izdelavo in okrasitev rjavčkov (brownijev, Set za izdelavo in okrasitev božičnih piškotov, Božična sladka hiška z okraski, + Dekor za božično zabavo + Lepilo za peko',
                url: ''
            }
        ]
    },
    {
        id: 18,
        prize_image: 'HR-02.png',
        grid_image: 'KALENDAR-adventski_18-24.jpg',
        tail_image: 'rep-zvijezde-18-dan-min.png',
        prizes: [
            {
                title: 'Mikser ručni MFQ49300 BOSCH',
                url: 'https://oetker-shop.si/izdelek/rocni-mesalnik-mfq49300-bosch/'
            }
        ]
    },
    {
        id: 19,
        prize_image: 'HR-06.png',
        grid_image: 'KALENDAR-adventski_19-24.jpg',
        tail_image: 'rep-zvijezde-19-dan-min.png',
        prizes: [
            {
                title: 'Set za tortu',
                url: 'https://oetker-shop.si/okusi-bozica/'
            },
            {
                title: 'Okrogel pekač z visokim robom, Lopatica za glajenje z visokim robom, 24 cm, 24 cm i Vrtljiv podstavek za torte in ostale slaščice',
                url: ''
            }
        ]
    },
    {
        id: 20,
        prize_image: 'HR-08.png',
        grid_image: 'KALENDAR-adventski_20-24.jpg',
        tail_image: 'rep-zvijezde-20-dan-min.png',
        prizes: [
            {
                title: 'Set za Božični kruh',
                url: 'https://oetker-shop.si/okusi-bozica/'
            },
            {
                title: 'Pekač za peko božičnega kruha + Vanilin sladkor 6x8g+Kvas instant 4/1 + Finesse pomarančna lupinica 3x6g +Kakao za kolače + Posoda za mešanje 3,2 l',
                url: ''
            }
        ]
    },
    {
        id: 21,
        prize_image: 'HR-03.png',
        grid_image: 'KALENDAR-adventski_21-24.jpg',
        tail_image: 'rep-zvijezde-21-dan-min.png',
        prizes: [
            {
                title: 'Božični bundle',
                url: 'https://oetker-shop.si/okusi-bozica/'
            },
            {
                title: 'Pekač v obliki božičnega drevesa, Preliv za kolače – temna kakavova glazura, Modelčki za piškote v obliki možička, 3 velikosti i ožični glamurozni mix 115g',
                url: ''
            }
        ]
    },
    {
        id: 22,
        prize_image: 'SLO-04.png',
        grid_image: 'KALENDAR-adventski_22-24.jpg',
        tail_image: 'rep-zvijezde-22-dan-min.png',
        prizes: [
            {
                title: 'Dizajnerski kalup za kuglof “Floral” Ø24 + mešavina za marmorni',
                url: ''
            }
        ]
    },
    {
        id: 23,
        prize_image: 'SLO-10.png',
        grid_image: 'KALENDAR-adventski_23-24.jpg',
        tail_image: 'rep-zvijezde-23-dan-min.png',
        prizes: [
            {
                title: 'Mikser ručni MFQ4020 BOSCH',
                url: 'https://oetker-shop.si/izdelek/rocni-mesalnik-mfq4020-bosch/'
            }
        ]
    },
    {
        id: 24,
        prize_image: 'HR-01.png',
        grid_image: 'KALENDAR-adventski_24-24.jpg',
        tail_image: 'rep-zvijezde-24-dan-min.png',
        prizes: [
            {
                title: 'Kuhinjski aparat MUM9B34S27 BOSCH',
                url: 'https://oetker-shop.si/izdelek/kuhinjski-robot-mum9b34s27-bosch/'
            }
        ]
    },
]

export default advent_days_SI