import React from 'react'
import mainContentStar from '../assets/img/grafika/ZVIJEZDA-natpis-SLO.png'
import mainContentPrizes from '../assets/img/SLO-ZBIRNE-nagrade.png'

const MainContent_SI = () => {

  return (
    <article className="mainContent">
      <div className="px-2 lg:px-8">
        <h1 className="font-bold text-2xl sm:text-3xl lg:text-4xl mb-8 lg:mb-10">Čas obdarovanj je tu</h1>
        <h2 className="font-bold text-xl sm:text-2xl lg:text-3xl max-w-[60ch] mx-auto mb-8 lg:mb-10">Sodelujte v veliki nagradni igri ADVENTNI KOLEDAR!</h2>
        <p>Naj vas pri pripravi sladic za najlepši čas v letu spremljajo najbolj aromatični izdelki iz Dr. Oetkerja.</p>
        <p>Priprava kolačev z najdražjimi je za marsikoga najljubša praznična dejavnost, in da bi letošnje praznike popestrili ter vanje vnesli še več veselja, smo za vas pripravili nagradni adventni koledar, ki prinaša zanimive in uporabne darilne pakete.</p>
        <p>Pokukajte v adventni koledar, saj boste v času nagradne igre, od 1. do 24. decembra 2024, vsak dan imeli priložnost osvojiti dragocene nagrade. </p>
        <br />
        <p><strong>Kako sodelovati?</strong></p>
        <p>Sledite preprostim korakom in nagrada je lahko prav vaša! </p>
        <p>Odprite vratca koledarja za določen datum, izpolnite polje na obrazcu s svojimi podatki in odgovorite na nagradno vprašanje.</p>
        <br />
        <p>Srečno!</p>
       {/* <br />
          <a href="https://www.oetker.si/si-si/svet-dekoracij/prejemniki-nagradne-igre" target="_blank" className="basic-button">Zmagovalci nagradnega natečaja</a>*/}
      </div>
      <div>
        <div className="flex justify-center">
          <img className="mainContentStar" src={mainContentStar} alt="advent information graphic" />
        </div>
        <div className="flex justify-center">
          <img className="mainContentPrizes" src={mainContentPrizes} alt="advent game prizes" />
        </div>
      </div>
    </article>
  )

}

export default MainContent_SI